import React from 'react';
import { Helmet } from 'react-helmet';

const SEO = ({ title, description, keywords, children }) => {
  return (
    <>
      <Helmet>
        <title>{title}</title>
        <meta name="description" content={description} />
        <meta name="keywords" content={keywords} />
        <meta name="author" content="AiGeniX" />
        <meta name="robots" content="index,follow" />
        <meta property="og:title" content={title} />
        <meta property="og:description" content={description} />
        <meta property="og:type" content="website" />
        <meta property="og:locale" content="en_US" />
        <meta property="og:image" content="URL_to_image" />
        <meta property="og:url" content="https://aigenix.co.bw" />
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content={title} />
        <meta name="twitter:description" content={description} />
        <meta name="twitter:image" content="URL_to_image" />
        <meta name="twitter:site" content="@twitterhandle" />
        <link rel="canonical" href="https://aigenix.co.bw" />
      </Helmet>
      {children}
    </>
  );
};

export default SEO;
