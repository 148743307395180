import React from 'react';
import { Box, Flex, Image, HStack, Collapse, VStack, useDisclosure } from "@chakra-ui/react";
import { useState } from "react";
import { Link as ScrollLink } from "react-scroll";
import Hamburger from "./Hamburger"; 
import blackLogo from "../assets/logo.svg";

const Header = ({ headerBg }) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [active, setActive] = useState("Home");

  const handleMobileNavToggle = () => {
    isOpen ? onClose() : onOpen();
  };

  const links = [
    { to: "home", text: "Home" },
    { to: "about", text: "About" },
    { to: "jobs", text: "Jobs" },
    { to: "contact-us", text: "Contact Us" },
  ];

  return (
    <Box
      as="nav"
      bg={headerBg}
      backdropFilter="blur(10px)"
      borderRadius={!isOpen ? "20px" : "none"}
      px={8}
      mt={isOpen ? 0 : 4}
      mx={{ base: isOpen ? 0 : 4, md: "auto" }}
      zIndex={99999}
      maxW="1200px"
      height={isOpen ? "100vh" : '60px'}
      position="fixed"
      top={0}
      left={0}
      right={0}
      transition="all 0.3s ease-in-out"
    >
      <Flex alignItems={isOpen ? 'start' : 'center'} justifyContent="center" height="100%">
      
        {/* Mobile Logo */}
        <Box display={{ base: "flex", md: "none" }} my={6} mr="auto">
          <ScrollLink
            to="home"
            smooth={true}
            duration={500}
            spy={true}
            offset={-60}
          >
            <Image src={blackLogo} w={40} h={10} alt="Logo" />
          </ScrollLink>
        </Box>

        {/* Mobile Menu Toggle (Hamburger) */}
        <Box display={{ base: "flex", md: "none" }} m={4}>
          <Hamburger isOpened={isOpen} handleMobileNavToggle={handleMobileNavToggle} />
        </Box>

        {/* Desktop Navigation Links */}
        <HStack
          spacing={10} // Reduced spacing between items
          color="white"
          height="100%"
          alignItems="center"
          display={{ base: "none", md: "flex" }}
          justify="center" // Center the navigation links
          flex="1" // Allow it to grow to take up available space
        >
          {links.slice(0, 2).map((link, index) => (
            <ScrollLink
              key={index}
              to={link.to}
              smooth={true}
              duration={500}
              spy={true}
              offset={-60}
              onSetActive={() => setActive(link.text)}
              style={{
                cursor: "pointer",
                position: "relative",
                height: "100%",
                lineHeight: "60px",
              }}
            >
              <Box
                _hover={{ textDecoration: "none", color: "grey" }}
                _after={{
                  content: '""',
                  position: "absolute",
                  bottom: "0",
                  left: "0",
                  width: active === link.text ? "100%" : "0",
                  height: "2px",
                  bg: "white",
                  transition: "width 0.3s",
                }}
              >
                {link.text}
              </Box>
            </ScrollLink>
          ))}
        </HStack>

        {/* Desktop Logo */}
        <Box 
          mx={{ base: 0, md: 4 }} // Adjusted margin for consistent spacing
          display={{ base: "none", md: "flex" }}
          justifyContent="center"
        >
          <ScrollLink
            to="home"
            smooth={true}
            duration={500}
            spy={true}
            offset={-60}
          >
            <Image src={blackLogo} w={40} h={10} alt="Parcks Small Black Logo" />
          </ScrollLink>
        </Box>

        {/* More Desktop Navigation Links */}
        <HStack
          spacing={10} // Reduced spacing between items
          color="white"
          height="100%"
          alignItems="center"
          display={{ base: "none", md: "flex" }}
          justify="center" // Center the navigation links
          flex="1" // Allow it to grow to take up available space
        >
          {links.slice(2).map((link, index) => (
            <ScrollLink
              key={index}
              to={link.to}
              smooth={true}
              duration={500}
              spy={true}
              offset={-60}
              onSetActive={() => setActive(link.text)}
              style={{
                cursor: "pointer",
                position: "relative",
                height: "100%",
                lineHeight: "60px",
              }}
            >
              <Box
                _hover={{ textDecoration: "none", color: "grey" }}
                _after={{
                  content: '""',
                  position: "absolute",
                  bottom: "0",
                  left: "0",
                  width: active === link.text ? "100%" : "0",
                  height: "2px",
                  bg: "white",
                  transition: "width 0.3s",
                }}
              >
                {link.text}
              </Box>
            </ScrollLink>
          ))}
        </HStack>
      </Flex>

      {/* Mobile Navigation Links */}
      <Collapse in={isOpen} animateOpacity>
        <VStack
          pos="absolute"
          top={'60px'}
          left={0}
          right={0}
          zIndex={9999}
          py={4}
          bg={'transparent'}
        >
          {links.map((link, index) => (
            <ScrollLink
              key={index}
              to={link.to}
              smooth={true}
              duration={500}
              spy={true}
              offset={-60}
              onClick={() => {
                setActive(link.text);
                handleMobileNavToggle();
              }}
              style={{
                cursor: "pointer",
                width: "100%",
                textAlign: "center",
                padding: "10px 0",
                color: "#FFFFFF",
                fontWeight: "600",
                fontSize: "14px",
              }}
            >
              {link.text}
            </ScrollLink>
          ))}
        </VStack>
      </Collapse>
    </Box>
  );
};

export default Header;
