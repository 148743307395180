import "../styles/Hamburger.css";
import React from "react";

const Hamburger = ({ handleMobileNavToggle, isOpened }) => {
  return (
    <div onClick={handleMobileNavToggle} className="hamburger-container">
      <div className={`menu ${isOpened ? 'opened' : ''}`}>
        <span className="line-1"></span>
        <span className="line-2"></span>
        <span className="line-3"></span>
      </div>
    </div>
  );
};

export default Hamburger;
