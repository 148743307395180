import React from 'react';
import backupImage from '../assets/backup.png';
import { Box,Image } from '@chakra-ui/react';

const FullScreenImage = () => (
    <Box height={'100vh'} width={'100vw'}>
        <Image src={backupImage
        } alt="Loading..." style={{ position: 'absolute', top: 0, left: 0, width: '100%', height: '100%', objectFit: 'cover' }} />
    </Box>

);

export default FullScreenImage;