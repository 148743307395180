import React, { Suspense, lazy } from 'react';
import Header from './components/Header';
import { Box } from '@chakra-ui/react';
import { useInView } from 'react-intersection-observer';
import Seo from './components/SEO';
import FullScreenImage from './components/FullScreenImage';

// Lazy load components
const Hero = lazy(() => import('./components/Hero'));
const Features = lazy(() => import('./components/Features'));
const WelcomeSection = lazy(() => import('./components/WelcomeSection'));
const ConnectSection = lazy(() => import('./components/ConnectionSection'));
const JoinSection = lazy(() => import('./components/JoinSection'));
const Footer = lazy(() => import('./components/Footer'));

function App() {
  // Track visibility of each section
  const { ref: heroRef, inView: heroInView } = useInView({ threshold: 0.1 });
  const { ref: welcomeRef, inView: welcomeInView } = useInView({ threshold: 0.1 });
  const { ref: featuresRef, inView: featuresInView } = useInView({ threshold: 0.1 });
  const { ref: connectRef, inView: connectInView } = useInView({ threshold: 0.5 });
  const { ref: joinRef, inView: joinInView } = useInView({ threshold: 0.1 });

  // Determine the header background color based on which section is in view
  let headerBg = "rgba(242, 242, 243, 0.1)"; // Default background

  if (heroInView) {
    headerBg = "rgba(242, 242, 243, 0.1)"; // Light background when Hero is in view
  } else if (welcomeInView) {
    headerBg = "rgba(0, 0, 0, 0.8)"; // Dark background when WelcomeSection is in view
  } else if (featuresInView) {
    headerBg = "rgba(0, 0, 0, 0.8)"; // Custom background when Features is in view
  } else if (connectInView) {
    headerBg = "rgba(242, 242, 243, 0.1)"; // Custom background when ConnectSection is in view
  } else if (joinInView) {
    headerBg = "rgba(0, 0, 0, 0.8)"; // Custom background when JoinSection is in view
  }

  return (
    <Seo  title="AiGeniX - Innovative AI-Driven Software Solutions in Botswana"
    description="AiGeniX specializes in building visually appealing intelligent software systems leveraging AI to solve complex problems and enhance operational efficiencies in Botswana."
    keywords="AI software in Botswana, intelligent systems, AI-driven solutions, software development Botswana, data analysis, machine learning, AI decision-making tools">
    <Box bg="gray.50">
      <Header headerBg={headerBg} />

      <Suspense fallback={<FullScreenImage/>}>
        <Box ref={heroRef}>
          <Hero />
        </Box>

        <Box ref={welcomeRef}>
          <WelcomeSection />
        </Box>

        <Box ref={featuresRef}>
          <Features />
        </Box>

        <Box ref={connectRef}>
          <ConnectSection />
        </Box>

        <Box ref={joinRef}>
          <JoinSection />
        </Box>

        <Footer />
      </Suspense>
    </Box>
    </Seo>
  );
}

export default App;
